import axios from 'axios'

/**
 * Get brokers
 * @returns {Promise<>}
 */
export default async function getPrices() {
  const res = await axios.get('/terminal/get-prices')

  return res.data
}
