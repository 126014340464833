import axios from 'axios'

/**
 * Get pattern
 * @param {number} patternId — pattern id
 * @returns {Promise<>}
 */
export default async function getPatternPrices(patternId) {
  const res = await axios.get('/terminal/get-pattern-prices', {
    params: {
      patternId,
    },
  })

  return res.data
}
