import axios from 'axios'
import { getToken, setToken } from './token'

/**
 * Login
 * @returns {Promise}
 */
export default async function createTestUser() {
  const res = await axios.get('/auth/create-test-user')
  const token = res.data.token

  setToken(token)

  return res.data
}
