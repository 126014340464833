import axios from 'axios'

const STORAGE_TOKEN_KEY = 'auth-token'
const HEADER_TOKEN_KEY = 'Token'

export function setToken(token) {
  localStorage.setItem(STORAGE_TOKEN_KEY, token)
  axios.defaults.headers.common[HEADER_TOKEN_KEY] = token
}

export function getToken() {
  return localStorage.getItem(STORAGE_TOKEN_KEY)
}

export function clearToken() {
  localStorage.removeItem(STORAGE_TOKEN_KEY)
  delete axios.defaults.headers.common[HEADER_TOKEN_KEY]
}
