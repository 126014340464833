import axios from 'axios'

/**
 * Get positions
 * @returns {Promise<>}
 */
export default async function getPositions() {
  const res = await axios.get('/terminal/get-positions')

  return res.data
}
