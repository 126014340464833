import axios from 'axios'

/**
 * Create position with safe
 * { ticker, direction, quantity, stopPrice, safePrice, takePrice }
 * @returns {Promise<>}
 */
export default async function createPositionWithSafe(params) {
  const res = await axios.get('/terminal/create-position-with-safe', {
    params,
  })

  return res.data
}
