import axios from 'axios'
import { clearToken } from './token'

/**
 * Check for auth
 * @returns {Promise}
 */
export default async function authCheck() {
  const res = await axios.get('/auth/logout')

  clearToken()

  return res.data
}
