import axios from 'axios'

/**
 * Cancel Position
 * @returns {Promise}
 */
export default async function cancelPosition(id) {
  const res = await axios.get('/terminal/cancel-position', {
    params: {
      id,
    },
  })

  return res.data
}
