import axios from 'axios'

/**
 * Get brokers
 * @returns {Promise<>}
 */
export default async function getOrders(ticker) {
  const res = await axios.get('/terminal/get-orders', {
    params: {
      ticker,
    },
  })

  return res.data
}
