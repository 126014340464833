import axios from 'axios'
import './socket'
import { getToken, setToken } from './token'

import authLogin from './auth-login'
import authCheck from './auth-check'
import authLogout from './auth-logout'
import createTestUser from './create-test-user'

import getBrokers from './get-brokers'
import getTickers from './get-tickers'
import getBalances from './get-balances'
import getOrders from './get-orders'
import getPrices from './get-prices'
import getCandles from './get-candles'

import getPatterns from './get-patterns'
import getPatternPrices from './get-pattern-prices'

import getPositions from './get-positions'
import cancelPosition from './cancel-position'

import createPositionWithSafe from './create-position-with-safe'

import levels from './levels'

axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN

/**
 * Load token headers
 */
const token = getToken()

if (token) {
  setToken(token)
}

export default {
  authLogin,
  authCheck,
  authLogout,

  createTestUser,

  getTickers,
  getBalances,
  getOrders,
  getPrices,
  getBrokers,
  getCandles,
  getPatterns,
  getPatternPrices,

  createPositionWithSafe,

  getPositions,
  cancelPosition,

  levels,
}
