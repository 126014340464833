import axios from 'axios'
import { setToken } from './token'

/**
 * Login
 * @returns {Promise}
 */
export default async function authLogin(params) {
  const res = await axios.get('/auth/login', { params })
  const token = res.data.token

  setToken(token)

  return res.data
}
