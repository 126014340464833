import axios from 'axios'

/**
 * Get brokers
 * @returns {Promise<>}
 */
export default async function getBalances() {
  const res = await axios.get('/terminal/get-balances')

  return res.data
}
