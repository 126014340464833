import axios from 'axios'

//
// Get figures
// @param {Array} params —  figures config
// @example
// figures: [
//   { type: 'ru21', options: { ... }, brokerId, ticker, timeframe },
//   { type: 'diapason', options: { ... }, brokerId, ticker, timeframe },
// ]
//
// @returns {Promise<>}
//
export default async function getPatterns(patterns) {
  const res = await axios.get('/terminal/get-patterns', {
    params: {
      patterns,
    },
  })

  return res.data
}
