import { io } from 'socket.io-client'

export const socket = io(process.env.VUE_APP_API_SOCKET, {
  reconnection: true,
})

socket.on('connect', () => {
  console.log('~~~> socket connected', socket.id)
})

socket.on('disconnect', () => {
  console.log('~~~> socket disconnected', socket.id)
})

// socket.on('candle', candle => {
//   console.log('~~~> candle', candle)
// })