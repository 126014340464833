import axios from 'axios'

/**
 * Get candles
 * @param {object} params — params
 * @param {number} params.brokerId – broker id
 * @param {string} params.ticker – ticker
 * @param {number} params.timeframe – timeframe
 * @param {number} params.startDate – start date
 * @param {number} params.endDate – end date
 * @param {number} params.limit – candle count
 * @returns {Promise<[Candle]>}
 */
export default async function getCandles(params) {
  const res = await axios.get('/get-candles', {
    params,
  })

  return res.data
}
