import axios from 'axios'

/**
 * Add new level
 * minPrice, maxPrice, startDate, endDate, state, direction
 * @returns {Promise<>}
 */
async function add(params) {
  const res = await axios.post('/terminal/rect/add', params)

  return res.data
}

/**
 * Update level
 * rectId, minPrice, maxPrice, startDate, endDate, state, direction
 * @returns {Promise<>}
 */
async function update(params) {
  const res = await axios.post('/terminal/rect/update', params)

  return res.data
}

/**
 * Remove level
 * rectId
 * @returns {Promise<>}
 */
async function remove(params) {
  const res = await axios.post('/terminal/rect/remove', params)

  return res.data
}

/**
 * Get all levels
 * @returns {Promise<>}
 */
async function get() {
  const res = await axios.get('/terminal/rect/get')

  return res.data
}

export default {
  add,
  update,
  remove,
  get,
}